import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>EXECUTIVE ASSISTANT</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Fresh graduate or has proven experience as an executive assistant or other relevant administrative support experience.</li>
          <li>In-depth understanding of entire MS Office suite.</li>
          <li>Bachelor’s Degree Graduate</li>
          <li>Ability to organize a daily workload by priorities.</li>
          <li>Must be able to meet deadlines in a fast-paced quickly changing environment</li>
          <li>A proactive approach to problem-solving with strong decision-making skills</li>
          <li>Professional level verbal and written communication skills</li>
          <li>Fast learner</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
